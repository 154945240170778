import Vue from "vue";
import Vuex from "vuex";
import axiosToken from "../../services/AxiosToken";
import store from "../index"; // vuex store instance

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    listaNotificaciones: [],
    cantidadNotificaciones: 0,
    listaNotificacionesError: "",
    cantidadNotificacion:"",
  },
  mutations: {
    SET_LISTANOTIFICACIONES(state, dato) {
      state.listaNotificaciones = dato;
    },
    SET_CANTIDADNOTIFICACIONES(state, dato) {
      state.cantidadNotificaciones = dato;
    },
    SET_LISTANOTIFICACIONESERROR(state, dato) {
      state.listaNotificacionesError = dato;
    },
    SET_CANTIDADNOTIFICACION(state, dato) {
      state.cantidadNotificacion = dato;
    },
    
  },
  actions: {
    getNotificacionesByRol({ dispatch, commit }) {
      store.commit("SET_BLOQUEADO", true);
      var qs = require("qs");
      var config = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };
       axiosToken.get("api/findNotificacionesByEmpresa/"+ this.state.user.user.empresa.id).then(function (response) {
          store.commit("notificaciones/SET_LISTANOTIFICACIONES", response.data);
          store.commit("notificaciones/SET_LISTANOTIFICACIONESERROR", null);
          store.commit("notificaciones/SET_CANTIDADNOTIFICACIONES", response.data.length);
          store.commit("SET_BLOQUEADO", false);
        })
        .catch(function(error) {
          store.commit("notificaciones/SET_LISTANOTIFICACIONES", null);
          store.commit(
            "notificaciones/SET_LISTANOTIFICACIONESERROR",
            "No se puedieron recuperar los anuncios."
            );
            store.commit("SET_BLOQUEADO", false);
        });
    },
    getNotificacionMenu({ dispatch, commit }) {
      var qs = require("qs");
      var config = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };
      
      axiosToken.get("api/getNotificacionMenu/"+this.state.user.user.id,config).then(function (response) {
        store.commit("notificaciones/SET_CANTIDADNOTIFICACION", response.data);
        })
        .catch(function(error) {
          Vue.swal("Portal de Proveedoras/es", error.response.data.message, "info");
        });
    },
    updateNotificacion({ dispatch, commit },id) {
      var qs = require("qs");
      var config = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };
      
      axiosToken.get("api/updateNotificacion/"+this.state.user.user.id+"/"+id).then(function (response) {
            store.commit("notificaciones/getNotificacionMenu");
        })
        .catch(function(error) {
          Vue.swal("Portal de Proveedoras/es", error.response.data.message, "info");
        });
    },
  },
  modules: {},
};
