import Vue from "vue";
import Vuex from "vuex";
import axiosToken from "../../services/AxiosToken";
import store from "../index"; // vuex store instance

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    manuales: [],
    terminos: [],
    otros: [],
    erroresDescargas: null
  },
  mutations: {
    SET_DESCARGAS_MANUALES(state, dato) {
      state.manuales = dato;
    },
    SET_DESCARGAS_TERMINOS(state, dato) {
      state.terminos = dato;
    },
    SET_DESCARGAS_OTROS(state, dato) {
      state.otros = dato;
    },    
    SET_ERRORES_DESCARGA(state, dato) {
      state.erroresDescargas = dato;
    },
  },
  actions: {
    findDescargasByTipoProveedor({ dispatch, commit }) {
      store.commit("SET_BLOQUEADO", true);

      var config = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      axiosToken.get("api/findDescargasByTipoProveedor/" + this.state.user.user.empresa.tipoProveedor.id, config)
        .then(function(response) {
          store.commit("descargas/SET_DESCARGAS_MANUALES", response.data.filter((x) => x.tipo_descarga == "MANUALES")
          );
          store.commit("descargas/SET_DESCARGAS_TERMINOS", response.data.filter((x) => x.tipo_descarga == "TERMINOS")
          );
          store.commit("descargas/SET_DESCARGAS_OTROS", response.data.filter((x) => x.tipo_descarga == "OTROS")
          );
        }).catch(function(error) {
          store.commit("SET_BLOQUEADO", false);
          store.commit("descargas/SET_ERRORES_DESCARGA", "Ocurrio un error al intentar recuperar los archivos");
        });
    },
    descargarArchivo({ dispatch, commit }, archivo) {
      store.commit("SET_BLOQUEADO", true);

      axiosToken({
        url: 'api/descargarArchivo/'+archivo,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
         store.commit("SET_BLOQUEADO", false);
         var fileURL = window.URL.createObjectURL(new Blob([response.data]));
         var fileLink = document.createElement('a');
      
         fileLink.href = fileURL;
         fileLink.setAttribute('download', archivo);
         document.body.appendChild(fileLink);
       
         fileLink.click();
    }).catch(e => {
      Vue.swal("Portal Proveedores", "Ocurrio un error al intentar descargar el archivo", "error");
    });
    },
    
    descargaXlS({ dispatch, commit }, obj) {
      store.commit("SET_BLOQUEADO", true);

      axiosToken.get("api/getCsvDownload", {responseType: 'blob',headers: {'Content-Type': 'application/octet-stream','Content-disposition':'attachment; filename=Excel.csv','Access-Control-Allow-Origin': '*',
          cartTramiteId: Number(obj.id_tramite),
          codeAtributo: obj.codigoAtributo
        },
    }).then((response) => {
        store.commit("SET_BLOQUEADO", false);        
        const blob = new Blob([response.data], { type: response.headers['content-type'] }); 
        // Create a temporary URL for the Blob
        
        const url = window.URL.createObjectURL(blob);
        console.log(`RESPONSE HEADERS ${response.headers['content-type']}`);
        
        // Create a link element and trigger the download
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = "descarga_"+ obj.id_tramite + '_' + obj.field.portal_leyenda + '_' +new Date().toJSON().slice(0,10).replaceAll('-','')+".csv"
        document.body.appendChild(a);
        a.click();
        
        // Clean up
        window.URL.revokeObjectURL(url);

        store.commit("SET_BLOQUEADO", false);
        Vue.swal("Portal proveedoras/es", "Se descarg&oacute; la búsqueda", "success");
    }).catch(e => {
      store.commit("SET_BLOQUEADO", false);
      Vue.swal("Portal Proveedores", JSON.stringify(e), "error");
    });
  }
  },
};
