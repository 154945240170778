import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import router from '../../router';
import axiosToken from "../../services/AxiosToken";
import store from '../index'; // vuex store instance
Vue.use(Vuex)

export default ({
    namespaced: true,
    state: {
        user : "",
    },
    mutations: {
        SET_USER(state, dato) {
            state.user = dato;
        },
    },
    actions: {
        sendDireccion({ dispatch, commit }, dataIn) {
            store.commit('SET_BLOQUEADO', true);
            return new Promise((resolve, reject) => {
            var qs = require('qs');
            
            var data = JSON.stringify(dataIn);
            var config = {
                method: 'post',
                url: '/api/saveDireccion',
                data: data,
                headers: {
                    'content-type': 'application/json;charset=utf-8',
                }
            };
            axiosToken(config).then(function (response) {
              
              store.commit('SET_BLOQUEADO', false);
              resolve(response.data)
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                reject(error)
            })
          })
        },
        getDireccionModal({ dispatch, commit }, idDomicilio) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var config = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access-Control-Allow-Origin': '*'
                },
            };
            return new Promise((res, rej) => {
                axiosToken.get("api/getDireccionModal/"+idDomicilio, config).then(function (response) {
                    return res(response.data)
                }).catch(function (error) {
                    return rej()
                })
            })
        },
    },
    modules: {}
})