import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import axiosToken from "../../services/AxiosToken";
import router from '../../router';
import store from '../index'; // vuex store instance
Vue.use(Vuex)

export default ({
    namespaced: true,
    state: {
        activo: "1",
        //atributos step1
        especialidades: [],
        domicilio_real: [],
        fields: [],
        servicios: [],
        tramite_ingresado: "",
        dataCalendar: "",
        provincias: "",
        proveedores:[],
    },
    mutations: {
        SET_ACTIVO(state, dato) {
            state.activo = dato;
        },
        SET_ESPECIALIDADES(state, dato) {
            state.especialidades.push(dato);
        },
        SET_ESPECIALIDADES_ARRAY(state, dato) {
            state.especialidades = dato;
        },
        SET_ESPECIALIDADES_CLEAR(state, dato) {
            state.especialidades = [];
        },
        SET_DIRECCIONES_REAL(state, dato) {
            state.domicilio_real = dato;
        },
        SET_TRAMITE_INGRESADO(state, dato) {
            state.tramite_ingresado = dato;
        },
        SET_ADD_FILE_TMP(state, dato) {
            state.file_tmp = dato;
        },
        SET_FIELDS(state, dato) {
            state.fields = dato;
        },
        SET_SERVICIOS(state, dato) {
            state.servicios = dato;
        },
        SET_DATA_CALENDAR(state, dato) {
            state.dataCalendar = dato;
        },
        SET_PROVINCIAS(state, dato) {
            state.provincias = dato;
        },
        SET_PROVEEDORES(state,dato){
            state.proveedores = dato;
        }
    },
    actions: {
        validarSiExistenAltas({ dispatch, commit }, dataIn) {
            store.commit('SET_BLOQUEADO', true);
            return new Promise((resolve, reject) => {
                var qs = require('qs');
                var config = {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Access-Control-Allow-Origin': '*',
                        'id': this.state.user.user.id,
                    },
                };
                axiosToken.get("api/verificarAltasEnCurso", config).then(function (response) {
                    resolve(response.data)
                }).catch(function (error) {
                    store.commit('SET_BLOQUEADO', false);
                    Vue.swal("Portal de Proveedoras/es", error.response.data.message, "info");
                    reject(error)
                })
            })
        },
        //TRAE LOS CAMPOS DE LOS STEP DEL ALTA DE PROVEEDOR
        getFields({ dispatch, commit }, dataIn) {
            store.commit('SET_BLOQUEADO', true);
            return new Promise((resolve, reject) => {
                var qs = require('qs');
                var config = {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Access-Control-Allow-Origin': '*',
                        'tipoProveedor': this.state.user.user.tipoProveedor.codigo,
                        'step': dataIn
                    },
                };

                axiosToken.get("api/camposAltaProveedor", config).then(function (response) {
                    if (response.data != null) {
                        store.commit("proveedor/SET_FIELDS", response.data);
                    }
                    store.commit('SET_BLOQUEADO', false);
                    resolve("OK")
                }).catch(function (error) {
                    store.commit('SET_BLOQUEADO', false);
                    Vue.swal({
                        title: "Portal Proveedores!",
                        text: error.response.data,
                        icon: "info"
                    }).then(function() {
                        window.location = "/home";
                    })
                    reject("ERROR")
                })
            })

        },
        getProveedores({ dispatch, commit }) {
            store.commit('SET_BLOQUEADO', true);
                var qs = require('qs');
                var config = {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                };
                axios.get("api/getAllProveedores", config).then(function (response) {
                    store.commit("proveedor/SET_PROVEEDORES", response.data);
                    store.commit('SET_BLOQUEADO', false);
                }).catch(function (error) {
                    store.commit('SET_BLOQUEADO', false);
                    Vue.swal("Portal de Proveedoras/es", error.response.data.message, "info");
                })

        },
        completarRazonSocialYCuit({ dispatch, commit }) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');

            var config = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access-Control-Allow-Origin': '*',
                    'id': this.state.user.user.id,
                },
            };
            axiosToken.get("api/findRazonSocialYCuit", config).then(function (response) {
                if (response.data != null) {
                    document.getElementById('cuit_datos_generales').value = response.data.cuit;
                    document.getElementById('cuit_datos_generales').readOnly = true;
                    document.getElementById('razón_social_datos_generales').value = response.data.razon_social;
                    document.getElementById('razón_social_datos_generales').readOnly = true;
                }
                store.commit('SET_BLOQUEADO', false);
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal("Portal de Proveedoras/es", error.response.data.message, "info");
            })
        },
        getProvincias({ dispatch, commit }) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');

            var config = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access-Control-Allow-Origin': '*'
                },
            };
            axiosToken.get("api/findProvincias", config).then(function (response) {
                if (response.data != null) {
                    store.commit("proveedor/SET_PROVINCIAS", response.data);
                }
                store.commit('SET_BLOQUEADO', false);
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal("Portal de Proveedoras/es", error.response.data.message, "info");
            })
        },
        getServicios({ dispatch, commit }, dataIn) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var config = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access-Control-Allow-Origin': '*',
                    'tipoProveedor': this.state.user.user.tipoProveedor.codigo,
                    'step': dataIn
                },
            };
            axiosToken.get("api/getServicios", config).then(function (response) {

                if (response.data != null) {
                    store.commit("proveedor/SET_SERVICIOS", response.data);
                }
                store.commit('SET_BLOQUEADO', false);
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal({
                    title: "Portal Proveedores!",
                    text: error.response.data,
                    icon: "info"
                }).then(function() {
                    window.location = "/home";
                })
            })
        },
        //STEP 1 ALTA PROVEEDOR
        getDireccion({ dispatch, commit }, codPostal) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var config = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access-Control-Allow-Origin': '*',
                    codPostal: Number(codPostal[0]),
                    provincia: Number(codPostal[1])
                },
            };
            return new Promise((res, rej) => {
                axiosToken.get("api/getDireccion", config).then(function (response) {
                    store.commit('SET_BLOQUEADO', false);
                    store.commit("proveedor/SET_DIRECCIONES_REAL", response.data);
                    return res()
                })
                    .catch(function (error) {
                        store.commit('SET_BLOQUEADO', false);
                        Vue.swal("Portal Proveedores", error.response.data.message, "info");
                    })
            })
        },
        sendDataGeneralProveedor({ dispatch, commit }, dataIn) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var data = JSON.stringify(dataIn[0]);
            var config = {
                method: 'post',
                url: '/api/altaProveedorStep1',
                data: data,
                headers: {
                    'content-type': 'application/json;charset=utf-8',
                    'token': this.state.auth.token
                }
            };
            axiosToken(config).then(function (response) {

                if (response.data != null) {
                    store.commit("proveedor/SET_TRAMITE_INGRESADO", response.data);
                }
                store.commit('SET_BLOQUEADO', false);
                store.commit("proveedor/SET_ACTIVO", parseInt(dataIn[1]) + 1);
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal("Portal de Proveedoras/es", error.response.data.message, "info");
            })
        },
        // STEP 2 ALTA PROVEEDOR
        sendDataProveedorEspecialidades({ dispatch, commit }, dataIn) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var data = JSON.stringify(dataIn[0]);
            var config = {
                method: 'post',
                url: '/api/altaProveedorStep2',
                data: data,
                headers: {
                    'content-type': 'application/json;charset=utf-8',
                    'token': this.state.auth.token
                }
            };
            axiosToken(config).then(function (response) {
                store.commit('SET_BLOQUEADO', false);
                store.commit("proveedor/SET_ACTIVO", parseInt(dataIn[1]) + 1);
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal("Portal de Proveedoras/es", error.response.data.message, "info");
            })
        },
        // STEP 3 ALTA PROVEEDOR
        sendFileCotizaDocumentacion({ dispatch, commit }, dataIn) {
            store.commit('SET_BLOQUEADO', true);
            var form = new FormData();
            for (let index = 0; index < dataIn[0].length; index++) {
                form.append("files", dataIn[0][index].file);
            }
            var config = {
                method: 'post',
                url: '/api/altaProveedorStep3?tramiteId=' + this.state.proveedor.tramite_ingresado,
                data: form,
                headers: {
                    'content-type': 'false',
                    "mimeType": "multipart/form-data",
                    'token': this.state.auth.token
                }
            };
            axiosToken(config).then(function (response) {
                store.commit('SET_BLOQUEADO', false);
                store.commit("proveedor/SET_ACTIVO", parseInt(dataIn[1]) + 1);
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal("Portal de Proveedoras/es", error.response.data.message, "info");
            })
        },
        // STEP 4 Y FIN

        sendFileCotizacion({ dispatch, commit }, dataIn) {

            store.commit('SET_BLOQUEADO', true);
            var form = new FormData();

            for (let index = 0; index < dataIn[0].length; index++) {
                form.append("files", dataIn[0][index].file);
            }
            var config = {
                method: 'post',
                url: '/api/altaProveedorStep4?tramiteId=' + this.state.proveedor.tramite_ingresado,
                data: form,
                headers: {
                    'content-type': 'false',
                    "mimeType": "multipart/form-data",
                    'token': this.state.auth.token
                }
            };
            axiosToken(config).then(function (response) {
                store.commit('SET_BLOQUEADO', false);
                store.commit("proveedor/SET_ACTIVO", parseInt(dataIn[1]) + 1);
                Vue.swal("Portal de Proveedoras/es", "El usuario fue cargado bajo el número " + response.data).then((result) => {
                    if (result.isConfirmed) {
                        store.commit('SET_BLOQUEADO', true);
                        location.href = "/ingresoGenerico";
                    }
                })
                // Ver de mostrar mensaje de que salio todo bien.
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal("Portal de Proveedoras/es", error.response.data.message, "info");
            })
        },
    },
    modules: {}
})