import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default ({
    namespaced: true,
    state: {
        token: "",
        refresh_token: "",
        
    },
    mutations: {
        SET_TOKEN(state, dato) {
            state.token = dato;
        },
        SET_REFRESHTOKEN(state, dato) {
            state.refresh_token = dato;
        },
    },
    actions: {

    },
    modules: {},
})