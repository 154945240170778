<template>
<div>
    <NavBar row="1" />
    <div class="container container-mio">
        <div class="row mt-3">
            <div class="col-md-12 p-0">
                <hr class="line-section">
            </div>
        </div>
    </div>
    <div id="layoutSidenav" class="body-landing">
        <Menu />
        <div id="layoutSidenav_content">
            <main class="landing-fondo">
                <LandingHeader></LandingHeader>
                <section class="section-facturacion container container-mio p-0">
                    <LandingSectionHeader section-header-text="¿Qué podés hacer?"></LandingSectionHeader>
                    <div class="row align-items-center mt-5 p-0">
                        <div class="col-md-2 d-flex justify-content-center align-content-center mb-md-0 mb-4">
                            <div class="section-facturacion__icon d-flex justify-content-center align-items-center">
                                <i class="fas fa-file-invoice-dollar"></i>
                            </div>
                        </div>
                        <div class="col-md-10 m-0">
                            <div class="row mb-4 mb-md-1">
                                <h2 class="section-facturacion__title text-md-start">Facturaci&oacute;n</h2>
                            </div>
                            <div class="row justify-content-center pb-3">
                                <div class="col-md-4 d-flex justify-content-center p-0">
                                    <div class="section-facturacion-item d-flex align-items-center gap-3 pb-3 pb-md-0">
                                        <div class="section-facturacion-item__icon ">
                                            <i class="fas fa-check"></i>
                                        </div>
                                        <div class="section-facturacion-item__text">
                                            Dar de alta facturas <br>
                                            electr&oacute;nicas.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 d-flex justify-content-center p-0">
                                    <div class="section-facturacion-item d-flex align-items-center gap-3 pb-3 pb-md-0">
                                        <div class="section-facturacion-item__icon ">
                                            <i class="fas fa-check"></i>
                                        </div>
                                        <div class="section-facturacion-item__text">
                                            Seguimiento <br> del status.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 d-flex justify-content-center p-0">
                                    <div class="section-facturacion-item d-flex align-items-center gap-3 pb-3 pb-md-0">
                                        <div class="section-facturacion-item__icon ">
                                            <i class="fas fa-check"></i>
                                        </div>
                                        <div class="section-facturacion-item__text">
                                            Informaci&oacute;n <br> sobre el pago.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center mt-5 mb-4 p-0">
                        <div class="col-md-2 d-flex justify-content-center align-content-center mb-md-0 mb-4">
                            <div class="section-facturacion__icon d-flex justify-content-center align-items-center">
                                <i class="fas fa-file-upload"></i>
                            </div>
                        </div>
                        <div class="col-md-10 m-0">
                            <div class="row mb-4 mb-md-1">
                                <h2 class="section-facturacion__title text-md-start">Cotizaci&oacute;n</h2>
                            </div>
                            <div class="row justify-content-center pb-3">
                                <div class="col-md-4 d-flex justify-content-center p-0">
                                    <div class="section-facturacion-item d-flex align-items-center gap-3 pb-3 pb-md-0">
                                        <div class="section-facturacion-item__icon ">
                                            <i class="fas fa-check"></i>
                                        </div>
                                        <div class="section-facturacion-item__text">
                                            Cargar presupuestos <br>
                                            para compulsas.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 d-flex justify-content-center p-0">
                                    <div class="section-facturacion-item d-flex align-items-center gap-3 pb-3 pb-md-0">
                                        <div class="section-facturacion-item__icon ">
                                            <i class="fas fa-check"></i>
                                        </div>
                                        <div class="section-facturacion-item__text">
                                            Verificar el estatus de <br> 
                                            adjudicaci&oacute;n.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 d-flex justify-content-center p-0">
                                    <div class="section-facturacion-item d-flex align-items-center gap-3 pb-3 pb-md-0">
                                        <div class="section-facturacion-item__icon ">
                                            <i class="fas fa-check"></i>
                                        </div>
                                        <div class="section-facturacion-item__text">
                                            Seguimiento de <br>
                                            compulsas.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="section-instrucciones container container-mio p-0">
                    <LandingSectionHeader section-header-text="INSTRUCCIONES PARA EL PRIMER INGRESO"></LandingSectionHeader>
                    <div class="row mt-2 mt-md-4 p-0 pt-2 mb-5 mb-md-0">
                        <div class="col-md-4">
                            <VideoSectionCard 
                            :section-header-text="'¿Cómo me registro?'"
                            :video-link="'VUE_APP_LINK_REGISTRO'"></VideoSectionCard>
                        </div>
                        <div class="col-md-4">
                            <VideoSectionCard 
                            :section-header-text="'¿Cómo cargo una factura?'"
                            :video-link="'VUE_APP_LINK_FACTURA'"></VideoSectionCard>
                        </div>
                        <div class="col-md-4">
                            <VideoSectionCard 
                            :section-header-text="'¿Cómo cargo una cotización?'"
                            :video-link="'VUE_APP_LINK_COTIZACION'"></VideoSectionCard>
                        </div>
                    </div>
                </section>
                <section class="section-beneficios container container-mio p-0">
                    <LandingSectionHeader section-header-text="Beneficios de la plataforma"></LandingSectionHeader>
                    <div class="container container-landing">
                        <div class="row text-center mt-md-4 mt-5 p-0">
                            <div class="col-md-3 p-0 pb-5 pb-md-0">
                                <span class="section-beneficios__icon">
                                    <i class="fas fa-list"></i> </span><br>
                                <span style="font-size: 1.2rem"><b>Seguimiento</b></span><br>
                                <span style="font-size: 1.2rem">de todo el proceso</span>
                            </div>

                            <div class="col-md-3 p-0 pb-5 pb-md-0">
                                <span class="section-beneficios__icon">
                                    <i class="fas fa-info-circle"></i>
                                </span><br>
                                <span style="font-size: 1.2rem"><b>Informaci&oacute;n</b></span><br>
                                <span style="font-size: 1.2rem">en tiempo real</span>
                            </div>

                            <div class="col-md-3 p-0 pb-5 pb-md-0">
                                <span class="section-beneficios__icon">
                                    <i class="fas fa-bell"></i>
                                </span><br>
                                <span style="font-size: 1.2rem"><b>Notificaciones</b></span><br>
                                <span style="font-size: 1.2rem">de cada status</span>
                            </div>

                            <div class="col-md-3 p-0 pb-5 pb-md-0">
                                <span class="section-beneficios__icon">
                                    <i class="fas fa-user-shield"></i>
                                </span><br>
                                <span style="font-size: 1.2rem"><b>Seguridad</b></span><br>
                                <span style="font-size: 1.2rem">de tu informaci&oacute;n</span>
                            </div>

                        </div>
                    </div>
                </section>
                <div class="fin-de-landing container container-mio"></div>
            </main>
        </div>
    </div>
</div>
</template>

<style>

.btn-link{
    color : #002f87 !important
}

.container-mio {
    max-width: 1100px;
}

.line-section {
    height: 10px !important;
    background: #45413d !important;
    opacity: 1
}

body {
    background-color: white !important;
}

.landing-fondo {
    background-color: white;
}

@media (min-width: 1400px) {
    .container-landing {
        max-width: 1200px;
    }
}

.section-facturacion__title {
    margin: 0 !important;
    text-align: center;
    text-transform: uppercase;
    color: #002F87;
    letter-spacing: -1px;
    font-weight: 700;
}

@media (min-width: 768px) {
    .section-facturacion__title {
        padding-left: 1%;
    }
}

.section-facturacion__icon {
    width: 80px;
    height: 80px;
    font-size: 45px;
    color: white;
    background: #002F87;
    border-radius: 50%;
}

.body-landing {
    position: relative;
    bottom: 74px;
}

@media (min-width: 992px) {
    .section-facturacion__icon {
        margin-right: 19%;
        margin-bottom: 25px;
    }
}

.section-facturacion-item {
    width: 90%;
    /*Aplicado en pantallas de menor tamaño para centrar la lista de items*/
    margin-left: 20%;
}

@media (min-width: 768px) {
    .section-facturacion-item {
        margin-left: 0;
    }
}

.section-facturacion-item__icon {
    vertical-align: middle;
    font-size: 40px;
    color: #25B4BD;
}

.section-facturacion-item__text {
    width: 100%;
    font-size: 1.2rem;
}

.section-beneficios__icon {
    color: #25B4BD;
    font-size: 60px;
    line-height: 0;
}

.section-facturacion,
.section-instrucciones,
.section-beneficios {
    min-height: 215px;
}

.fin-de-landing {
    height: 40px;
    border-top: 15px solid #25B4BD;
    background-color: #002F87;

}
</style>

<script>
import NavBar from "../components/landing/navBar"
import router from '@/router/index.js' // Vue router instance
import spinner from '../components/spinner'
import Menu from '../components/landing/menu'

import LandingHeader from '../components/landing/landingHeader'
import VideoSectionCard from '../components/landing/videoSectionCard'
import LandingSectionHeader from '../components/landing/sectionHeader.vue'
import props from '../../properties.config'
export default {
    name: "Home",
    components: {
        NavBar,
        spinner,
        Menu,
        LandingHeader,
        LandingSectionHeader,
        props,
        VideoSectionCard,
    },
    created: function () {
        this.removeMenu();
    },

    methods: {
        redirectRegister() {
            router.push("/registrarse");
        },
        redirectLogin() {
            router.push("/login");
        },
        removeMenu() {
            var ww = document.body.clientWidth;
            //By RAA, eliminar clase debido a como esta construido el tema de bootstrap public\assets\css\sbAdminPro\styles.css
            if (ww < 992) {
                document.body.classList.remove("sidenav-toggled");
            } else {
                document.body.classList.add("sidenav-toggled");
            }
        }
    },
    computed: {},
};
</script>
