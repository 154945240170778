<template>
    <div class="d-flex flex-column justify-content-center align-items-center p-0 mb-0 mb-md-5">
            <div class="video-section-card__icon p-3 p-md-0 mb-0 mb-md-3">
                <i class="fab fa-youtube"></i>
            </div>
            <div class="p-0 video-section-card__text text-center">
                <a @click="redirectVideo(videoLink)" class="link-video">{{sectionHeaderText}}</a>
            </div>
    </div>
</template>
<style scoped>
.video-section-card__icon {
    text-align: center;
    line-height: 0;
    font-size: 60px;
    color: #002F87;
}

.video-section-card__text {
    font-size: 1.2rem;
    text-transform: uppercase;
    color: #002F87;
}

.link-video{
    color:#002F87!important;
}
.link-video:hover{
    cursor:pointer;
    border-bottom: 1px;
}
</style>
<script>
import props from '../../../properties.config'
export default {
    name: "VideoSectionCard",
    components: {
        props
    },
    props: {
        sectionHeaderText: String,
        videoLink: String
    },
    methods: {
        redirectVideo(link) {
            return window.open(props[link], '_blank').focus();
        }
    }
};
</script>
