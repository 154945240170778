<template>
    <div class="landing-section-header container container-mio">
        <h2 class="m-0 text-landing-header">{{SectionHeaderText}}</h2>
    </div>
</template>
<style scoped>    
    .text-landing-header {
        text-transform: uppercase;
        color: #002F87;
        letter-spacing: 0.02rem;
        font-weight: 900 !important;
    }

    .landing-section-header {
        text-align: center;
        background-color: #f5f5f5;
        padding: 0.75rem 0;
    }
    .container-mio{
        max-width: 1100px;
    }
</style>
<script>
export default {
    name: "LandingSectionHeader",
    components: {
        
    },
    props: {
        SectionHeaderText: String
    }
};
</script>
