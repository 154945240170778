import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import axiosToken from "../../services/AxiosToken";
import router from "../../router";
import store from "../index"; // vuex store instance
import UtilJS from "../../assets/js/util";
Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    listaPreguntas: [],
    cantidadPreguntas: 0,
    ayudaError : null
  },
  mutations: {
    SET_LISTAPREGUNTAS(state, dato) {
      state.listaPreguntas = dato;
    },    
    SET_CANTIDADPREGUNTAS(state, dato) {
      state.cantidadPreguntas = dato;
    },    
    SET_LISTAAYUDAERROR(state, dato) {
      state.ayudaError = dato;
    }
  },
  actions: {
    findPreguntasByTipoProveedor({ dispatch, commit }) {
      store.commit("SET_BLOQUEADO", true);

      var qs = require("qs");
      var config = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      axiosToken
        .get("api/findPreguntasByTipoProveedor/" + this.state.user.user.empresa.tipoProveedor.id)
        .then(function(response) {
          store.commit("ayuda/SET_LISTAPREGUNTAS", response.data);
          store.commit("ayuda/SET_CANTIDADPREGUNTAS", response.data.length);
          store.commit("ayuda/SET_LISTAAYUDAERROR", null);
          store.commit("SET_BLOQUEADO", false);
        })
        .catch(function(error) {
          store.commit("SET_BLOQUEADO", false);
          store.commit("ayuda/SET_LISTAPREGUNTAS", null);
          store.commit(
            "ayuda/SET_LISTAAYUDAERROR",
            "No se puedieron recuperar las preguntas."
          );
        });
    },
  },
};
