import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from '../view/landing'
import Store from '../store/index'; // vuex store instance

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'landing',
    component: Landing
  }, 
  {
    path: '/login',
    name: 'Login',
    component: ()=> import('../view/login.vue')
  },
  {
    path: '/registrarse',
    name: 'Registrarse',
    component: ()=> import('../view/registrarse.vue')
  }, 
  {
    path: '/recuperarPass',
    name: 'RecuperarPass',
    component: ()=> import('../view/recuperarPass.vue')
  },   
  //home
  {
    path: '/home',
    name: 'Home',
    component: ()=> import('../view/home.vue')
  },  
  {
    path: '/autogestion',
    name: 'autogestion',
    component: ()=> import('../view/menu/autogestion.vue')
  },  
 
  {
    path: '/generico',
    name: 'generico',
    component: ()=> import('../view/menu/generico/misGestiones.vue')
  },
  {
    path: '/ingresoGenerico',
    name: 'ingresoGenerico',
    component: ()=> import('../view/menu/generico/tramiteNuevo.vue')
  },
  {
    path: '/consultaGenerico',
    name: 'consultaGenerico',
    component: ()=> import('../view/menu/generico/tramiteConsulta.vue')
  },
  {
    path: '/mensajes',
    name: 'mensajes',
    component: ()=> import('../view/menu/mensajes.vue')
  },
  {
    path: '/notificaciones',
    name: 'notificaciones',
    component: ()=> import('../view/menu/notificaciones.vue')
  },
  {
    path: '/descargas',
    name: 'descargas',
    component: ()=> import('../view/menu/descargas.vue')
  },
       

  {
    path: '/help',
    name: 'help',
    component: ()=> import('../view/menu/ayuda/ayuda.vue')
  },
  {
    path: '/altaNuevoTramiteGeneric',
    name: 'altaNuevoTramiteGeneric',
    component: ()=> import('../view/menu/generico/altaNuevoTramiteGenerico.vue')
  },
  {
    path: '/loginEmpresas',
    name: 'loginEmpresas',
    component: ()=> import('../view/loginEmpresas.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  try {
    let state = Store;
    if(to.path == "/login" || to.path =="/registrarse" || to.path =="/recuperarPass" || to.path =="/"){
      state.commit('SET_ROUTER',to.path);
      next();
    }else{
      if (state.state.auth.token != null && state.state.auth.token.length>1) {
          if(state.state.router == "/altaNuevoTramiteGeneric" && to.path== "/altaNuevoTramiteGeneric"){
            state.commit('SET_ROUTER',"/home");
            next("/home");
          }else{
            state.commit('SET_ROUTER',to.path);
            next();
          }
        } else {
          state.commit('SET_ROUTER',"/");
          next("/");
      }
    }
  } catch (error) {
    state.commit('SET_ROUTER',"/");
    next("/")
  }
})




export default router
