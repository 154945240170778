import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import router from '../../router';
import store from '../index'; // vuex store instance
Vue.use(Vuex)

export default ({
    namespaced: true,
    state: {
        activo : "",
    },
    mutations: {
        SET_ACTIVO(state, dato) {
            state.activo = dato;
        },
    },
    actions: {

    },
    modules: {}
})