<template>
<div>
    <div id="layoutSidenav_nav">
        <nav class="sidenav shadow-right sidenav-light">
            <div class="sidenav-menu">
                <div class="nav accordion" id="accordionSidenav">
                    <div class="sidenav-menu-heading">Accesos</div>     
                     <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPages">
                            <a class="nav-link" @click="redirectLogin">Ingresar</a>
                            <a class="nav-link" @click="redirectRegistrarse">Registrarse</a>
                        </nav>    
                </div>
            </div>
        </nav>
    </div>
</div>
</template>

<style>
</style>

<script>
import router from '@/router/index.js' // Vue router instance
export default {
    components: {},
    mounted: function () {
        //ejectuar cuando inicia
    },
    methods: {
         redirectRegistrarse(){
            router.push("/registrarse")
        },
        redirectLogin(){
            router.push("/login")
        }
    },
    computed: {
    },
    props: {
        pantalla: Number
    }
}
</script>>
