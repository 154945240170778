export default {
  methods: {
    formateDate(date) {
      if (date != null && date.length > 1) {
        return date
          .split(" ")[0]
          .split("-")
          .reverse()
          .join("-");
      }
      return "";
    },
    primeraLetraMayus(dato) {
      // if(dato != null && dato.length>0){
      //   return dato.replace(
      //       /\w\S*/g,
      //       function(txt) {
      //           return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      //       }
      //   );
      // }else{
      //   return dato;
      // }
      return dato;
    },
    isNumber: function (evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();;
        } else {
            return true;
        }
    },
    getTipoProveedor(id){
      var map = new Object(); // or var map = {};
      map["001"] = "ASOCIACION O FEDERACION DE CLINICAS Y SANATORIOS";
      map["002"] = "AUDITOR";
      map["003"] = "CENTRO ASISTENCIAL AMBULATORIO";
      map["004"] = "CENTRO ASISTENCIAL de MEDIANA COMPLEJIDAD";
      map["005"] = "CENTRO ASISTENCIAL de ALTA COMPLEJIDAD";
      map["006"] = "CENTRO DE DIAGNOSTICO";
      map["007"] = "CENTRO DE REHABILITACIÓN";
      map["008"] = "COLEGIO O ASOCIACION DE PROFESIONALES";
      map["009"] = "COLEGIO, CAMARA O FEDERACION DE FARMACIAS";
      map["010"] = "ESTABLECIMIENTO GERIATRICO";
      map["011"] = "EXAMENES DE SALUD";
      map["012"] = "FARMACIA";
      map["013"] = "GERENCIAMIENTO DE PRESTACIONES";
      map["014"] = "HOSPITAL PUBLICO";
      map["015"] = "INSUMOS MEDICOS";
      map["016"] = "INTERNACION DOMICILIARIA";
      map["017"] = "INVESTIGADOR DE SINIESTROS";
      map["018"] = "POLICONSULTORIO";
      map["019"] = "PRESTADOR DE RECUPERO";
      map["020"] = "PREVENTOR";
      map["021"] = "PROFESIONAL ESPECIALISTA ";
      map["022"] = "PROVEEDOR";
      map["023"] = "RED DE SERVICIOS";
      map["024"] = "SERVICIO DE HOSPEDAJE Y/O HOTELERIA";
      map["025"] = "TRASLADO EN AMBULANCIA";
      map["026"] = "TRASLADO AEREO";
      map["027"] = "TRASLADO EN REMIS o TAXI";
      
      return this.primeraLetraMayus(map[id]);

    },
    createAlert(
      title,
      summary,
      details,
      severity,
      dismissible,
      autoDismiss,
      appendToId
    ) {
      var iconMap = {
        info: "fa fa-info-circle",
        success: "fa fa-thumbs-up",
        warning: "fa fa-exclamation-triangle",
        danger: "fa ffa fa-exclamation-circle",
      };

      var iconAdded = false;

      var alertClasses = ["alert", "animated", "flipInX"];
      alertClasses.push("alert-" + severity.toLowerCase());

      if (dismissible) {
        alertClasses.push("alert-dismissible");
      }

      var msgIcon = $("<i />", {
        class: iconMap[severity],
      });

      var msg = $("<div />", {
        class: alertClasses.join(" "),
      });

      if (title) {
        var msgTitle = $("<h4 />", {
          html: title,
        }).appendTo(msg);

        if (!iconAdded) {
          msgTitle.prepend(msgIcon);
          iconAdded = true;
        }
      }

      if (summary) {
        var msgSummary = $("<strong />", {
          html: summary,
        }).appendTo(msg);

        if (!iconAdded) {
          msgSummary.prepend(msgIcon);
          iconAdded = true;
        }
      }

      if (details) {
        var msgDetails = $("<p />", {
          html: details,
        }).appendTo(msg);

        if (!iconAdded) {
          msgDetails.prepend(msgIcon);
          iconAdded = true;
        }
      }

      if (dismissible) {
        var msgClose = $("<span />", {
          class: "close", // you need to quote "class" since it's a reserved keyword
          "data-dismiss": "alert",
          html: "<i class='fa fa-times-circle'></i>",
        }).appendTo(msg);
      }

      $("#" + appendToId).prepend(msg);

      if (autoDismiss) {
        setTimeout(function() {
          msg.addClass("flipOutX");
          setTimeout(function() {
            msg.remove();
          }, 1000);
        }, 5000);
      }
    },
  },
};
