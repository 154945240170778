import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import axios from 'axios'
import ReadMore from 'vue-read-more';
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import axiosToken from '../src/services/AxiosToken'

Vue.use(VueSweetalert2);
Vue.use(ReadMore);
Vue.use(axios)
Vue.use(VueVirtualScroller)
 
Vue.config.productionTip = false

//axios.defaults.baseURL = 'http://localhost:8080'
//axiosToken.defaults.baseURL = 'http://localhost:8080'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
