//STEPS ALTA PROVEEDOR
const ALTA_PROVEEDOR_STEP_1 = "1";
const ALTA_PROVEEDOR_STEP_2 = "2";



//constate del atributo para registrar un tramite de registro
const ATRIBUTTE_CODIGO_TIPO_TRAMITE = "registracion";
//constantes de atributos del registro de usuario
const ATRIBUTTE_CUIT = "AT001";
const ATRIBUTTE_CUIT_DESC = "CUIT";
const ATRIBUTTE_NOMBRE = "AT002";
const ATRIBUTTE_NOMBRE_DESC = "Nombre Solicitante";
const ATRIBUTTE_APELLIDO = "AT003";
const ATRIBUTTE_APELLIDO_DESC = "Apellido Solicitante";
const ATRIBUTTE_TELEFONO = "AT004";
const ATRIBUTTE_TELEFONO_DESC = "Telefono";
const ATRIBUTTE_TP_PROVEEDOR = "AT005";
const ATRIBUTTE_TP_PROVEEDOR_DESC = "Tipo Proveedor";
const ATRIBUTTE_EMAIL = "AT006";
const ATRIBUTTE_EMAIL_DESC = "Email";
const ATRIBUTTE_EMAIL_CONFIRMACION = "AT007";
const ATRIBUTTE_EMAIL_CONFIRMACION_DESC = "Confirmacion email";
const ATRIBUTTE_RESENA = "AT037";
const ATRIBUTTE_RESENA_DESC = "Reseña";
//ALTA PROVEEDOR
const ATRIBUTTE_CODIGO_TIPO_ALTA_PROVEEDOR="TR0007";
const ATRIBUTTE_CODIGO_TIPO_ALTA_PROVEEDOR_STEP_2="TR0007";
//ALTA PROVEEDOR STEP1
const ATRIBUTTE_RAZON_SOCIAL = "AT001";

//url login
const URL_LOGIN="/api/getLogin"

export default {
    //STEP ALTA PROVEEDOR
    ALTA_PROVEEDOR_STEP_1:ALTA_PROVEEDOR_STEP_1,
    ALTA_PROVEEDOR_STEP_2:ALTA_PROVEEDOR_STEP_2,
    //atributo codigo de tramite
    ATRIBUTTE_CODIGO_TIPO_TRAMITE: ATRIBUTTE_CODIGO_TIPO_TRAMITE,
    ATRIBUTTE_CODIGO_TIPO_ALTA_PROVEEDOR : ATRIBUTTE_CODIGO_TIPO_ALTA_PROVEEDOR,
    ATRIBUTTE_CODIGO_TIPO_ALTA_PROVEEDOR_STEP_2:ATRIBUTTE_CODIGO_TIPO_ALTA_PROVEEDOR_STEP_2,
    //atributo de registro
    ATRIBUTTE_CUIT: ATRIBUTTE_CUIT,
    ATRIBUTTE_CUIT_DESC: ATRIBUTTE_CUIT_DESC,
    ATRIBUTTE_NOMBRE: ATRIBUTTE_NOMBRE,
    ATRIBUTTE_NOMBRE_DESC: ATRIBUTTE_NOMBRE_DESC,
    ATRIBUTTE_APELLIDO: ATRIBUTTE_APELLIDO,
    ATRIBUTTE_APELLIDO_DESC: ATRIBUTTE_APELLIDO_DESC,
    ATRIBUTTE_TELEFONO_DESC: ATRIBUTTE_TELEFONO_DESC,
    ATRIBUTTE_TELEFONO: ATRIBUTTE_TELEFONO,
    ATRIBUTTE_TP_PROVEEDOR: ATRIBUTTE_TP_PROVEEDOR,
    ATRIBUTTE_TP_PROVEEDOR_DESC: ATRIBUTTE_TP_PROVEEDOR_DESC,
    ATRIBUTTE_EMAIL: ATRIBUTTE_EMAIL,
    ATRIBUTTE_EMAIL_DESC: ATRIBUTTE_EMAIL_DESC,
    ATRIBUTTE_EMAIL_CONFIRMACION: ATRIBUTTE_EMAIL_CONFIRMACION,
    ATRIBUTTE_EMAIL_CONFIRMACION_DESC: ATRIBUTTE_EMAIL_CONFIRMACION_DESC,
    ATRIBUTTE_RESENA: ATRIBUTTE_RESENA,
    //ALTA PROVEEDOR STEP 1
    ATRIBUTTE_RAZON_SOCIAL : ATRIBUTTE_RAZON_SOCIAL,

    ATRIBUTTE_RESENA_DESC: ATRIBUTTE_RESENA_DESC,
    URL_LOGIN:URL_LOGIN,
}