<template>
<div>
    <nav class="mt-5 topnav-mio navbar navbar-expand justify-content-sm-start justify-content-between navbar-light bg-white container container-navbar p-0" id="sidenavAccordion">
        <div class="container container-nav p-md-0">
            <button v-if="row=='1'" class="d-md-none btn btn-icon btn-transparent-dark order-1 order-lg-0 me-2 ms-lg-2 me-lg-0" id="sidebarToggle"><i class="fas fa-bars"></i></button>
            <a class="navbar-brand ps-0">
                <img @click="redirectLanding" class="img-navbar mb-0" style="height:70px !important" src="assets/img/PART_Positivo.svg">
            </a>
            <div v-if="row=='1'" class="d-none d-md-flex d-lg-flex" style="position: relative;  top: 30px;">
                <ul class="navbar-nav d-flex gap-2">
                    <li class="nav-item navbar-item d-flex p-0">
                        <span class="p-1">
                            <i class="fas fa-lock navbar-icon"></i>
                        </span>
                        <a class="nav-link btn-navbar" @click="redirectLogin">Ingresar</a>
                    </li>
                    <li class="nav-item navbar-item d-flex p-0">
                        <span class="p-1">
                            <i class="fas fa-user-plus navbar-icon"></i>
                        </span>
                        <a class="nav-link btn-navbar" @click="redirectRegistrarse">Registrarse</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

</div>
</template>

<style>
.topnav-mio {
    z-index:200;
    height: 3.625rem;
}



.img-navbar {
    cursor: pointer;
    height: 55px !important;
    margin: 1px;
    padding: 0;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 10px;
    margin-bottom: 10px;
}

.navbar-item {
    color: #002F87 !important;
    align-content: center;
    align-items: center;
}

.container-navbar {
    max-width: 1100px;
}

.navbar-item:hover * {
    color: var(--bs-body-color) !important;
    transition: color 0.15s ease-in-out;
}

.navbar-icon {
    font-size: 20px;
}

.btn-navbar {
    color: #002F87 !important;
    cursor: pointer;
    font-size: 18px;
    display: flex;
}

@media (min-width: 1400px) {
    .container-nav {
        max-width: 1200px;
    }
}
</style>

<script>
import router from '@/router/index.js' // Vue router instance
export default {
    components: {},
    mounted: function () {
        //ejectuar cuando inicia
    },
    methods: {
        redirectLanding() {
            window.location.href = "/";
        },
        redirectLogin() {
            // router.push("/login")
            window.location.href = "/login";

        },
        redirectRegistrarse() {
            // router.push("/registrarse")
            window.location.href = "/registrarse";

        }
    },
    computed: {
        //mapea store con la vista
    },
    props: {
        row: String
    }
}
</script>>
