import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import router from '@/router/index.js' // Vue router instance
import createPersistedState from 'vuex-persistedstate';
import {createStore} from 'vuex-extensions'
import login from './store/login'
import registrarse from './store/registro'
import mensajes from './store/mensajes'
import notificaciones from './store/notificaciones'
import menu from './store/menu'
import proveedor from './store/proveedor'
import auth from '../store/auth'
import user from '../store/store/user'
import ayuda from '../store/store/ayudaStore'
import descargas from '../store/store/descargasStore'
import tramiteGenerico from '../store/store/tramiteGenerico'

Vue.use(Vuex)

const getDefaultState = () => {
    return {
        items: [registrarse],
        status: "",        
    }
}

export default createStore(Vuex.Store, {
    state: {
        bloqueado: false,
        router:"",
    },
    plugins: [createPersistedState()],
    mutations: {
        SET_BLOQUEADO(state, dato) {
            state.bloqueado = dato;
        },
        resetState(state) {
            this.reset();
        },
        SET_ROUTER(state, dato) {
            state.router = dato;
        },
    },
    actions: {
        storeReset({dispatch, commit}) {
            this.reset();
        },
    },
    modules: {
        login, registrarse, menu, proveedor,auth,user,tramiteGenerico, mensajes, notificaciones, ayuda, descargas
    },
})

