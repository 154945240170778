import axios from 'axios';
import store from '../store/index'; // vuex store instance
import router from '../router';
import Vue from 'vue'
 
const axiosInstance = axios.create({});




//interceptor request
axiosInstance.interceptors.request.use((config)=>{
    let state = store;
    if (!config.params) {
        config.params =  {};
    }
    config.params['proveedor'] = state.state.user.user.empresa.tipoProveedor.id;
    config.params['user_id'] = state.state.user.user.id;
    config.params['router'] = state.state.router;
    config.headers.token=state.state.auth.token;
    config.headers.refresh_token=state.state.auth.refresh_token;
    return config;
  })

  //interceptor response
  axiosInstance.interceptors.response.use((response) => {
        if(response.status == 200 || response.status == 201){
        let state = store;
        if(response.headers.token != null){
            state.commit('auth/SET_TOKEN',response.headers.token);
            state.commit('auth/SET_REFRESHTOKEN',response.headers.refresh_token);
        }
            return Promise.resolve(response);
        }else{
            return Promise.reject(response);
        }
    }, (error) => {
        try {
            if(error.response.status == 500){
                return Promise.reject(error);
            }else{
                Vue.swal({
                    title: "Tu sesión ha expirado",
                    text: "Volvé a iniciar sesión",
                    icon: "info"
                }).then(function() {
                    router.push("/login");
                })
                return error.response;
            }
        } catch (error) {
            Vue.swal({
                title: "Tu sesión ha expirado",
                text: "Volvé a iniciar sesión",
                icon: "info"
            }).then(function() {
                router.push("/login");
            })
            return error.response;
        }

});




  export default axiosInstance;