import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import axiosToken from "../../services/AxiosToken";
import router from '../../router';
import store from '../index'; // vuex store instance

Vue.use(Vuex)

export default ({
    namespaced: true,
    state: {
        user: "",
        pass: "",
        passActual: "",
        passNueva1: "",
        passNueva2: "",
        empresas_by_user:[],
    },
    mutations: {
        SET_USER(state, dato) {
            state.user = dato;
        },
        SET_PASS(state, dato) {
            state.pass = dato;
        },
        SET_PASS_ACTUAL(state, dato) {
            state.passActual = dato;
        },
        SET_PASS_NUEVA_1(state, dato) {
            state.passNueva1 = dato;
        },
        SET_PASS_NUEVA_2(state, dato) {
            state.passNueva2 = dato;
        },
        SET_EMPRESAS_BY_USER(state, dato) {
            state.empresas_by_user = dato;
        },
    },
    actions: {
        async getLogin({dispatch, commit}) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var data = qs.stringify({
                'username': this.state.login.user,
                'password': this.state.login.pass
            });
            var config = {
                method: 'post',
                url: '/api/getLogin',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
                },
                data: data
            };
            return axios(config).then(function (response) { 
                store.commit('SET_BLOQUEADO', false);
                store.commit("menu/SET_ACTIVO", "home");
                store.commit('auth/SET_TOKEN',response.data.token.access_token);
                store.commit('auth/SET_REFRESHTOKEN',response.data.token.refresh_token);
                if(response.data.empresas != null && response.data.empresas.length==1){
                    //FIX temporal hasta que todas las empresas tengan tipo de proveedor (corrida de delta_0006.sql):
                    //En caso de que la empresa aun no tenga tipo de proveedor
                    //Se asigna el tipo de proveedor del usuario a la empresa
                    if (!response.data.token.database_user.empresa.tipoProveedor) {
                        response.data.token.database_user.empresa.tipoProveedor = response.data.token.database_user.tipoProveedor;
                        response.data.empresa.gent_tipo_proveedor_id = response.data.token.database_user.tipoProveedor.id;                    
                    }
                    store.commit('user/SET_USER',response.data.token.database_user);
                    store.dispatch("mensajes/getMensajesByUsuario",1)
                    .then((response)=>{
                        if (response instanceof Error) {
                            throw response;        
                        }
                        router.push("/home");
                        return response;
                    })
                    .catch((error)=>{
                        return Vue.swal("Portal de Proveedoras/es", error['response']? JSON.stringify(error.response.data): error.message, "info");
                    })
                }else{
                    store.commit('login/SET_EMPRESAS_BY_USER',response.data.empresas);
                    router.push("/loginEmpresas")
                }
                return response;
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                return Vue.swal("Portal de Proveedoras/es", error['response']? JSON.stringify(error.response.data): error.message, "info");
            })
        },
        verificarPassword({dispatch, commit}) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var data = qs.stringify({
                'username': this.state.login.user,
                'password': this.state.login.passActual
            });
            var config = {
                method: 'post',
                url: '/api/getLogin',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
                },
                data: data
            };
            
            axios(config).then((response) => {
                store.dispatch("login/restorePassword")
            }).catch(function (error) {
                console.error(error);
                store.commit('SET_BLOQUEADO', false);
                Vue.swal("Portal de Proveedoras/es", "La contraseña actual ingresada no es correcta.", "info");
            })
        },
        restorePassword({dispatch, commit}) {
            
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            let obj = new Object();
            obj.password=this.state.login.passNueva1;
            obj.username=this.state.login.user;
            var data = JSON.stringify(obj);
            var config = {
                method: 'post',
                url: '/api/changePass',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*"
                },
                data: data
            };
            axiosToken(config).then(function (response) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal("Portal de Proveedoras/es", "La contraseña se cambió correctamente.", "info").then(function () {
                    store.commit("menu/SET_ACTIVO", "home");
                    router.push("/home");
                });
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal("Portal de Proveedoras/es", "Usuario y/o contraseña invalidos", "info");
            })
        },
        getRecuperarPass({dispatch, commit},user) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var config = {
                headers: { 'Content-Type': 'application/json;charset=UTF-8', 'Access-Control-Allow-Origin': '*' },
            };
            axios.get("api/getRecuperarPass/"+user,config).then(function (response) {
                store.commit('SET_BLOQUEADO', false);

                Vue.swal({
                    title: "Proveedoras/es",
                    text: "Se envio la nueva contraseña al email ingresado",
                    icon: "info"
                }).then(function() {
                    window.location = "/login";
                })
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                Vue.swal("Portal de Proveedoras/es", error.response.data, "info");
            })
        },

        async setEmpresaByUser({dispatch, commit},empresa_id) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            return axios.get("api/getLoginEmpresa",{
                headers: { 'Content-Type': 'application/json;charset=UTF-8', 'Access-Control-Allow-Origin': '*' },
                params: {
                    email: this.state.login.user,
                    empresaID: empresa_id
                }
            }).then(function (response) {
                store.commit('SET_BLOQUEADO', false);
                //FIX temporal hasta que todas las empresas tengan tipo de proveedor (corrida de delta_0006.sql):
                //En caso de que la empresa aun no tenga tipo de proveedor
                //Se asigna el tipo de proveedor del usuario a la empresa
                if (!response.data.empresa.tipoProveedor) {
                    response.data.empresa.tipoProveedor = response.data.tipoProveedor;
                    response.data.empresa.gent_tipo_proveedor_id = response.data.tipoProveedor.id;                    
                }
                store.commit('user/SET_USER',response.data);
                store.dispatch("mensajes/getMensajesByUsuario",1)
                    .then((response)=>{
                        if (response instanceof Error) {
                            throw response;        
                        }
                        router.push("/home");
                        return response;
                    })
                    .catch((error)=>{
                        return Vue.swal("Portal de Proveedoras/es", error['response']? JSON.stringify(error.response.data): error.message, "info");
                    })
                return response;
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                return Vue.swal("Portal de Proveedoras/es", "Se generó un error al seleccionar la empresa", "info");
            })
        },

    },
    modules: {},

})