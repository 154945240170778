import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import axiosToken from "../../services/AxiosToken";
import router from '../../router';
import store from '../index'; // vuex store instance
import UtilJS from '../../assets/js/util'
Vue.use(Vuex)

export default ({
  namespaced: true,
  state: {
    cuit:"",
    nombre:"",
    apellido:"",
    telefono:"",
    tipoProveedor:"",
    email:"",
    emailConfirmacion:"",
    resena:"",
    codigoArea:"",
    tramitesIngresados:[],
    errorListaTramite:"",
  },
  mutations: {
    SET_CUIT(state, dato) {
       state.cuit = dato;
    },
    SET_NOMBRE(state, dato) {
       state.nombre = dato;
    },
    SET_APELLIDO(state, dato) {
        state.apellido = dato;
     },
     SET_TELEFONO(state, dato) {
        state.telefono = dato;
     },
     SET_CODIGO_AREA(state, dato) {
      state.codigoArea = dato;
   },
     SET_TIPOPROVEEDOR(state, dato) {
        state.tipoProveedor = dato;
     },
     SET_EMAIL(state, dato) {
        state.email = dato;
     },
     SET_EMAILCONFIRMACION(state, dato) {
        state.emailConfirmacion = dato;
     },
     SET_LISTRAMITESINGRESADOS(state,dato){
      state.tramitesIngresados=dato;
     },
     SET_ERRORLISTATRAMITE(state,dato){
      state.errorListaTramite=dato;
     },
     SET_RESENA(state,dato){
      state.resena=dato;
     },
     
  },
  actions: {
    clearState(){
      store.commit('registrarse/SET_NOMBRE',"");
      store.commit('registrarse/SET_APELLIDO',"");
      store.commit('registrarse/SET_CUIT',"");
      store.commit('registrarse/SET_TELEFONO',"");
      store.commit('registrarse/SET_TIPOPROVEEDOR',"");
      store.commit('registrarse/SET_EMAIL',"");
      store.commit('registrarse/SET_EMAILCONFIRMACION',"");
    },
    //este metodo registra al nuevo proveedor
    getRegistrarse({ dispatch, commit },dataIn ) {  
      store.commit('SET_BLOQUEADO',true);
      var qs = require('qs');
      var data = JSON.stringify(dataIn[0]);
      
        var config = {
        method: 'post',
        url: '/api/createTramite',
        data : data,
        headers: { 
            'content-type': 'application/json;charset=utf-8' 
        }
        };
        axiosToken(config).then(function (response) {
            store.commit('SET_BLOQUEADO',false);
            store.commit('registrarse/SET_NOMBRE',"");
            store.commit('registrarse/SET_APELLIDO',"");
            store.commit('registrarse/SET_CUIT',"");
            store.commit('registrarse/SET_TELEFONO',"");
            store.commit('registrarse/SET_TIPOPROVEEDOR',"");
            store.commit('registrarse/SET_EMAIL',"");
            store.commit('registrarse/SET_EMAILCONFIRMACION',"");
            Vue.swal.fire({
               title: 'Portal Proveedoras/es',
               icon: 'info',
               html: 'Se genero el '+dataIn[2]+' exitosamente. En breve recibira un email con la información para ingresar.',
               showCancelButton: false,
               focusConfirm: true,
               confirmButtonColor: '#3085d6',
               confirmButtonText: 'Aceptar',
           }).then((result) => {
              if(data[1]==0){
                 router.push("/login")
              }else{
                  router.push("/registracion")
              }
           })
        })
        .catch(function (error) {
            store.commit('SET_BLOQUEADO',false);
            Vue.swal("Portal de Proveedoras/es",error.response.data.message,"info");
        })
    },
   //este metodo registra al nuevo usuario
   createNewUser({ dispatch, commit },dataIn ) {  
      store.commit('SET_BLOQUEADO',true);
      var qs = require('qs');
      var data = JSON.stringify(dataIn[0]);
      var config = {
         method: 'post',
         url: '/api/registrarEstadoAbierto',
         data : data,
         headers: { 
            'Content-Type': 'application/json;charset=utf-8','Access-Control-Allow-Origin': '*','token':''
         }
      };
      axios(config).then(function (response) {
         store.commit('SET_BLOQUEADO',false);
         store.commit('registrarse/SET_NOMBRE',"");
         store.commit('registrarse/SET_APELLIDO',"");
         store.commit('registrarse/SET_CUIT',"");
         store.commit('registrarse/SET_TELEFONO',"");
         store.commit('registrarse/SET_TIPOPROVEEDOR',"");
         store.commit('registrarse/SET_EMAIL',"");
         store.commit('registrarse/SET_EMAILCONFIRMACION',"");
         Vue.swal.fire({
            title: 'Recibimos tu registración',
            icon: 'success',
            html: 'La solicitud será analizada y te enviaremos un e-mail para informarte su resolución. GestionID : '+response.data,
            showCancelButton: false,
            focusConfirm: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Aceptar',
         }).then((result) => {
            if(dataIn[1]==0){
               router.push("/")
            }else{
               router.push("/registracion")
            }
         })
        })
        .catch(function (error) {
            store.commit('SET_BLOQUEADO',false);
            store.commit('SET_BLOQUEADO',false);
            // store.commit('registrarse/SET_NOMBRE',"");
            // store.commit('registrarse/SET_APELLIDO',"");
            // store.commit('registrarse/SET_CUIT',"");
            // store.commit('registrarse/SET_TELEFONO',"");
            // store.commit('registrarse/SET_TIPOPROVEEDOR',"");
            // store.commit('registrarse/SET_EMAIL',"");
            // store.commit('registrarse/SET_EMAILCONFIRMACION',"");

            Vue.swal.fire({
               title: 'Portal Proveedoras/es',
               icon: 'info',
               html: ''+error.response.data,
               showCancelButton: false,
               focusConfirm: true,
               confirmButtonColor: '#3085d6',
               confirmButtonText: 'Aceptar',
            })


        })
    },
     
    getRegistros({ dispatch, commit } ) {  
      store.commit('SET_BLOQUEADO',true);
      var qs = require('qs');
      
      var config = {
         headers: {'Content-Type': 'application/json;charset=UTF-8','Access-Control-Allow-Origin': '*'},
     };
     axiosToken.get("api/findTramitesByUsuarioByType/"+this.state.user.user.id+"/1").then(function (response) {
            store.commit('registrarse/SET_LISTRAMITESINGRESADOS',response.data);
            store.commit('registrarse/SET_ERRORLISTATRAMITE',null);
            store.commit('SET_BLOQUEADO',false);
        })
        .catch(function (error) {
            store.commit('SET_BLOQUEADO',false);
            store.commit('registrarse/SET_LISTRAMITESINGRESADOS',null);
            //Vue.swal("Portal Proveedores",error.response.data.message,"info");
            store.commit('registrarse/SET_ERRORLISTATRAMITE',"No se encontraron registros para el usuario ingresado.");
        })
    },
   
  },
  modules: {}
})