<template>
    <section class="landing-header container  p-5" :style="{ backgroundImage: 'url(' + require('@/assets/img/fondo-header.jpg') + ')' }">
        <div class=" container landing-header__text d-flex flex-column justify-content-center text-center" style="margin: 0 auto">
            <h1><b>PORTAL DE PROVEEDORES Y PROVEEDORAS</b></h1>
            <h3>LA NUEVA PLATAFORMA DE GESTI&Oacute;N DE PROVINCIA ART</h3>
        </div>
    </section>
</template>
<style scoped>
    h1,h3 {
        text-transform: uppercase;
        color: white;
    }

    h1 {
        font-size: 2rem;
    }

    h3 {
        letter-spacing: 0.03rem;
        font-weight: 200;
    }

    .landing-header {        
        background-size: cover;
        max-width: 1100px;
    }

    .landing-header__text {
        min-height: 170px;
        height: 17vh;
    }

</style>
<script>
export default {
    name: "LandingHeader",
    components: {
        
    },
};
</script>
